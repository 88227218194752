<template>
  <AutoComplete
    forceSelection
    :placeholder="placeholder"
    v-model="internalValue"
    :optionLabel="(o) => `${o.name} (${o.zip_code})`"
    :suggestions="options"
    @complete="searchCities"
    @change="selectCity"
    :pt="{
      input: { class: 'w-full ' },
    }"
  >
    <template #option="slotProps">
      {{ slotProps.option.name }} ({{ slotProps.option.zip_code }})
    </template>
  </AutoComplete>
</template>
<script>
import _ from "lodash";

export default {
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: {
      default: "",
    },
    design: {
      type: Number,
      default: 1,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      async handler(val) {
        if (val) {
          const city = await this.getCityById(val);

          if (city) {
            this.options = [city];
            this.internalValue = city;
          }
        }
      },
    },
  },
  data() {
    return {
      internalValue: null,
      loading: false,
      options: [],
    };
  },
  methods: {
    selectCity(e) {
      if (e.value?.id) {
        this.$emit("update:modelValue", this.returnObject ? e.value : e.value.id);
      }
    },
    searchCities: _.debounce(function (searchQuery) {
      this.loading = true;

      return this.$api
        .call("get", this.$api.route("cities.index"), null, {
          q: searchQuery.query,
        })
        .then((payload) => {
          this.options = payload;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }, 300),
    getCityById(id) {
      this.loading = true;

      return this.$api
        .call("get", this.$api.route("cities.show", { city: id }))
        .then((payload) => {
          return payload;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
